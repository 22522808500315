import { combineReducers } from 'redux';
import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/searchHistoryExport/actionTypes';

export const exportSearchHistoryReducer = (state = defaultStore.exportSearchHistory.exportSearchHistoryState, action) => {
    switch (action.type) {
        case actionTypes.START_EXPORTING_SEARCH_HISTORY:
            return { ...state, isExportingSearchHistory: true };
        case actionTypes.STOP_EXPORTING_SEARCH_HISTORY:
            return { ...state, isExportingSearchHistory: false };
        default:
            return state;
    }
};

export const searchHistoryExportReducer = combineReducers({
    exportSearchHistoryState: exportSearchHistoryReducer
});
