import * as actionTypes from 'api/searchHistoryExport/actionTypes';
import * as actions from 'api/searchHistoryExport/actions';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { call, put } from 'redux-saga/effects';
import { searchHistoryExportApi } from 'api/searchHistoryExport/remoteApi';
import { showFile } from 'views/fileOpener';

function* exportSearchHistory(action) {   
    yield put(actions.startExportingSearchHistory());
    const inputData = action.searchParameters;
    const outputData = yield apiCall(searchHistoryExportApi, inputData);
    yield put(actions.stopExportingSearchHistory());
    yield call(showFile, outputData.fileContents, outputData.fileDownloadName, outputData.contentType);
}

export function* watchExportSearchHistory() {
    yield guardedTakeEvery(actionTypes.EXPORT_SEARCH_HISTORY, exportSearchHistory);
}
