export const getLayoutInfoState = (state) => state.layout.layoutInfo;
export const getIsFetchingState = (state) => state.layout.isFetching;
export const getLayoutInfoMenuState = (state) => state.layout.layoutInfo.menu;
export const getLanguagesState = (state) => state.layout.layoutInfo.languages;
export const getResourcesState = (state) => state.layout.layoutInfo.resources;
export const getCurrentLanguage = (state) => state.layout.layoutInfo.languages?.find((l) => l.isActive)?.name;
export const getFormattingCulture = (state) => state.layout.layoutInfo.formattingCulture;
export const getSupportedTranslations = (state) => state.layout.layoutInfo.supportedTranslations;
export const getMessagePlaceholder = (state) => state.messagePlaceholder;
export const getIsMassEditEnabled = (state) => state.layout.layoutInfo.isMassEditEnabled;
