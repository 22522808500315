import { all } from 'redux-saga/effects';
import { watchFetchLayoutInfo } from 'api/layout/sagas';
import { watchLoginUser, watchLogoutUser } from 'api/user/login';
// Catalog
import {
    watchFetchTreeElements as watchFetchProductTreeElements,
    watchRefreshTreeElements as watchRefreshProductTreeElements,
    watchFetchTreeConfig as watchFetchProductTreeConfig,
} from 'api/catalog/productTree/sagas';
import { watchFetchAllElements, watchFetchSearchTermData, watchFetchExport, watchUpdateResource } from 'api/languageManagement/sagas';
import { watchFetchAvailableExportDefinitions, watchAddNewExportJob } from 'api/catalog/exportElement/sagas';
import {
    watchFetchAvailableTypesForParentMetaType as watchFetchAvailableCatalogTypesForParentMetaType,
    watchCreateNewElement as watchCreateNewCatalogElement,
} from 'api/catalog/newElement/sagas';
import {
    watchFetchElementData as watchFetchCatalogElementData,
    watchFetchElementConfiguration as watchFetchCatalogElementConfiguration,
    watchSaveElementFormData as watchSaveCatalogElementFormData,
} from 'api/catalog/element/sagas';
import { watchFetchAvailableTypes as watchFetchAvailableCatalogTypes, watchUpdateElementType as watchUpdateCatalogElementType } from 'api/catalog/changeElementType/sagas';
import { watchDeleteElement as watchDeleteCatalogElement } from 'api/catalog/deleteElement/sagas';
import { watchCopyElement as watchCopyCatalogElement } from 'api/catalog/copyElement/sagas';
import { watchChangeElementId as watchChangeCatalogElementId } from 'api/catalog/changeElementId/sagas';
import { watchUpdateActivationOptions as watchUpdateCatalogActivationOptions } from 'api/catalog/elementActivationOptions/sagas';
// Media
import {
    watchFetchMediaElements,
    watchFileNamesInfo,
    watchFetchMediaData,
    watchFetchMediaConfiguration,
    watchSaveMediaFormData,
    watchDeleteMediaElement,
    watchMediaInfos,
    watchFetchAttachmentAssociations,
    watchSupportedExtensions
} from 'api/media/sagas';
// CMS
import { watchFetchCmsTreeElements, watchRefreshCmsTreeElements, watchFetchCmsTreeConfig } from 'api/cms/cmsTree/sagas';
// UserManagement
import {
    watchFetchTreeElements as watchFetchUserManagementTreeElements,
    watchRefreshTreeElements as watchRefreshUserManagementTreeElements,
    watchFetchTreeConfig as watchFetchUserManagementTreeConfig,
} from 'api/userManagement/userManagementTree/sagas';
import {
    watchFetchAvailableTypesForParentMetaType as watchFetchAvailableUserManagementTypesForParentMetaType,
    watchCreateNewElement as watchCreateNewUserManagementElement,
} from 'api/userManagement/newElement/sagas';
import {
    watchFetchElementData as watchFetchUserManagementElementData,
    watchFetchElementConfiguration as watchFetchUserManagementElementConfiguration,
    watchSaveElementFormData as watchSaveUserManagementElementFormData,
} from 'api/userManagement/element/sagas';
import {
    watchFetchAvailableTypes as watchFetchAvailableUserManagementTypes,
    watchUpdateElementType as watchUpdateUserManagementElementType,
} from 'api/userManagement/changeElementType/sagas';
import { watchDeleteElement as watchDeleteUserManagementElement } from 'api/userManagement/deleteElement/sagas';
import { watchCopyElement as watchCopyUserManagementElement } from 'api/userManagement/copyElement/sagas';

import { watchChangeElementId as watchChangeUserManagementElementId } from 'api/userManagement/changeElementId/sagas';
import { watchChangeUserEmail as watchChangeUserManagementUserEmail } from 'api/userManagement/changeUserEmail/sagas';
import { watchUpdateActivationOptions as watchUpdateUserManagementActivationOptions } from 'api/userManagement/elementActivationOptions/sagas';
// Search
import { watchPredefinedFetchSearchResults, watchPredefinedFetchSearchOptions, watchFetchSearchResults, watchChangeSearchPhrase } from 'api/search/sagas';
import { watchUpdatePassword } from 'api/userManagement/passwordUpdate/sagas';
import { watchFetchActions, watchRunAction } from 'api/actions/sagas';
import { watchFetchConfiguration } from 'api/configuration/sagas';
// Data Management
import { watchFetchAllExports, watchDownloadExportFile, watchRemoveExport } from 'api/dataManagement/sagas';
import { watchChangeFieldSuggestions } from 'api/propertyValueSuggestions/sagas';
import { watchFetchExportDefinitionInitialOptions, watchFetchMetaTypeProperties, watchAddNewExportDefinition } from 'api/catalog/exportElement/sagas';
import { watchExportSearchHistory } from 'api/searchHistoryExport/sagas';

export default function* rootSaga() {
    yield all([
        watchFetchLayoutInfo(),
        watchLoginUser(),
        watchLogoutUser(),
        watchFetchConfiguration(),
        // Catalog
        watchFetchProductTreeElements(),
        watchRefreshProductTreeElements(),
        watchFetchProductTreeConfig(),
        watchFetchAvailableCatalogTypesForParentMetaType(),
        watchCreateNewCatalogElement(),
        watchFetchCatalogElementData(),
        watchFetchCatalogElementConfiguration(),
        watchSaveCatalogElementFormData(),
        watchFetchAvailableCatalogTypes(),
        watchUpdateCatalogElementType(),
        watchDeleteCatalogElement(),
        watchCopyCatalogElement(),
        watchChangeCatalogElementId(),
        watchUpdateCatalogActivationOptions(),
        watchFetchAvailableExportDefinitions(),
        watchAddNewExportJob(),
        // Media
        watchFetchMediaElements(),
        watchFileNamesInfo(),
        watchFetchMediaData(),
        watchFetchMediaConfiguration(),
        watchSaveMediaFormData(),
        watchDeleteMediaElement(),
        watchMediaInfos(),
        watchFetchAttachmentAssociations(),
        watchSupportedExtensions(),
        // CMS
        watchFetchCmsTreeElements(),
        watchRefreshCmsTreeElements(),
        watchFetchCmsTreeConfig(),
        // UserManagement
        watchFetchUserManagementTreeElements(),
        watchRefreshUserManagementTreeElements(),
        watchFetchUserManagementTreeConfig(),
        watchFetchAvailableUserManagementTypesForParentMetaType(),
        watchCreateNewUserManagementElement(),
        watchDeleteUserManagementElement(),
        watchCopyUserManagementElement(),
        watchFetchUserManagementElementData(),
        watchFetchUserManagementElementConfiguration(),
        watchSaveUserManagementElementFormData(),
        watchChangeUserManagementElementId(),
        watchChangeUserManagementUserEmail(),
        watchFetchAvailableUserManagementTypes(),
        watchUpdateUserManagementElementType(),
        watchUpdatePassword(),
        watchUpdateUserManagementActivationOptions(),
        // Search
        watchPredefinedFetchSearchResults(),
        watchPredefinedFetchSearchOptions(),
        watchFetchSearchResults(),
        watchChangeSearchPhrase(),
        watchFetchAllElements(),
        watchFetchSearchTermData(),
        watchFetchExport(),
        // LanguageManagement
        watchUpdateResource(),
        // DataManagement
        watchFetchAllExports(),
        watchFetchExportDefinitionInitialOptions(),
        watchFetchMetaTypeProperties(),
        watchAddNewExportDefinition(),
        watchDownloadExportFile(),
        watchRemoveExport(),
        // Actions
        watchFetchActions(),
        watchRunAction(),
        // Suggestions
        watchChangeFieldSuggestions(),
        // Search History Export
        watchExportSearchHistory()
    ]);
}
